import * as React from "react"
import { PropsWithChildren } from "react"
import IMobileSearchInput from "./types"
import { Input, SearchInputWrapper, SearchButton } from "./styles"
import { Icon } from "@iconify/react"
import search32Regular from "@iconify-icons/fluent/search-32-regular"

const MobileSearchInput = ({
  margin,
}: PropsWithChildren<IMobileSearchInput>) => {
  return (
    <SearchInputWrapper margin={margin && margin}>
      <Input placeholder="Szukaj..." />
      <SearchButton>
        <Icon icon={search32Regular} />
      </SearchButton>
    </SearchInputWrapper>
  )
}

export default MobileSearchInput
