import * as React from "react"
import {useEffect, useRef, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {
    Main,
    DropDownContainer,
    DropDownHeader,
    DropDownList,
    DropDownListContainer,
    ListItem,
} from "./styles";
import IDropDownMenu from "./types";
import ProfileIcon from "../../../data/images/profile_icon.png"

const DropDownMenu = ({ unverifiedUserEmail }: IDropDownMenu) => {
    const dropdownRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const {logout, isAuthenticated, isLoading} = useAuth0();

    useEffect(() => {
        const pageClickEvent = (e) => {
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
                setIsOpen(!isOpen);
            }
        };

        if (isOpen) {
            window.addEventListener('click', pageClickEvent);
        }

        return () => {
            window.removeEventListener('click', pageClickEvent);
        }

    }, [isOpen]);

    const toggling = () => {
        setIsOpen(!isOpen);
        if(!isOpen) {
            window.removeEventListener('click', logoutClicked);
        }
    }

    const logoutClicked = () => {
        setIsOpen(false);
        logout();
    };

    if(isLoading) {
        return (
            <></>
        )
    }

    if(isAuthenticated || unverifiedUserEmail) {
        return (
            <Main ref={dropdownRef}>
                <DropDownContainer>
                    <DropDownHeader onClick={toggling}>
                        <img src={ProfileIcon} />
                        <div>
                            {"Profil"}
                        </div>
                    </DropDownHeader>
                    {isOpen && (
                        <DropDownListContainer>
                            <DropDownList>
                                <ListItem onClick={() => logoutClicked()} key={Math.random()}>
                                    WYLOGUJ
                                </ListItem>
                            </DropDownList>
                        </DropDownListContainer>
                    )}
                </DropDownContainer>
            </Main>
        );
    } else return (
        <></>
    )
}

export default DropDownMenu;