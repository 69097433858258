import styled, { css } from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { IButtonWrapper, IButtonClickable } from "./types"
import { GreenSpan, BlackSpan } from "../../Global"

export const BtnGreenSpan = styled(GreenSpan)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

export const BtnBlackSpan = styled(BlackSpan)`
  font-weight: ${props => props.theme.fontWeight.bold};
`

export const ButtonWrapper = styled.div<IButtonWrapper>`
  margin: ${props => (props.margin ? props.margin : "unset")};

  ${props =>
    props.type === "landing" &&
    css`
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    `}

  display: inline-block;
`

export const ButtonClickable = styled.div<IButtonClickable>`
  border: 2px solid ${props => props.theme.color.lightGreen};
  ${props =>
    props.type === "blog" &&
    css`
      border: 2px solid ${props => props.theme.color.black};
    `}
  padding: 10px;
  transition: 0.3s;

  ${props =>
    props.type === "landing" &&
    css`
      background-color: ${props.theme.color.lightGreen};
    `}
  ${props =>
    props.type === "blog" &&
    css`
      background-color: transparent;
    `}
    ${props =>
    props.type === "advertise" &&
    css`
      background-color: ${props => props.theme.color.rose};
    `}

  & > span {
    transition: 0.5s;
  }

  &:hover {
    background-color: ${props => props.theme.color.darkestGreen};
    & > span {
      color: ${props => props.theme.color.lightGreen};
    }
  }
`
export const ButtonLink = styled(AnchorLink)``
