import React from "react"
import MobileLoginButton from "../MobileLoginButton"
import {
  MobileMenuContainer,
  MenuCard,
  MobileNavLink,
  LinkWrapper,
} from "./styles"
import IMobileMenu from "./types"

const navLinksData = [
  {
    id: 1,
    name: "Strona Główna",
    slug: "/",
  },
  {
    id: 2,
    name: "Nasi Partnerzy",
    slug: "/trainers",
  },
  {
    id: 3,
    name: "Kategorie",
    slug: "/#",
  },
  {
    id: 4,
    name: "Blog",
    slug: "/blog",
  },
  {
    id: 5,
    name: "Zostań Partnerem",
    slug: "/#",
  },
]

const MobileMenu = (props: IMobileMenu) => {
  const { isActive, display } = props
  return (
    <>
      {display === true && (
        <MobileMenuContainer isActive={isActive}>
          <MenuCard isActive={isActive}>
            <nav>
              {navLinksData.map((s, idx) => (
                <LinkWrapper key={idx}>
                  <MobileNavLink to={s.slug} title={s.name} />
                </LinkWrapper>
              ))}
              <MobileLoginButton />
            </nav>
          </MenuCard>
        </MobileMenuContainer>
      )}
    </>
  )
}

export default MobileMenu
