import * as React from "react"
import { ColoredSection, ContactWrapper } from "./styles"
import { Container } from "../../Global"

const Footer = () => {
  return (
    <footer>
      <ColoredSection>
        <Container>
          <ContactWrapper>
            <p>KONTAKT </p>
            <p>
              Napisz do nas maila: <br /> info@click4fit.me
            </p>
            <p>
              Odwiedź nas: <br /> Żmigrodzka 244, 51-131, Wrocław, Poland
            </p>
            <p>
              Zadzwoń do nas: <br /> +48 535 124 505
            </p>
          </ContactWrapper>
        </Container>
      </ColoredSection>
    </footer>
  )
}

export default Footer
