import styled from "styled-components"
import { IMobileSearchInputWrapper } from "./types"

export const SearchInputWrapper = styled.div<IMobileSearchInputWrapper>`
  top: 10px;
  left: 120px;
  position: absolute;
  margin: ${props => (props.margin ? props.margin : "unset")};
  width: calc(100% - 240px);
  max-width: 420px;
`
export const Input = styled.input`
  background-color: ${props => props.theme.color.darkestGreen};
  padding-left: 50px;
  height: 48px;
  outline: none;
  border: none;
  width: 400px;
  color: ${props => props.theme.color.white};
  @media (max-width: 1050px) {
    width: 100%;
  }
`

export const SearchButton = styled.div`
  top: 0;
  position: absolute;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    transition: 0.3s;
    color: ${props => props.theme.color.gray};
    width: 32px;
    height: 32px;
  }

  &:hover {
    cursor: pointer;
    & > svg {
      color: ${props => props.theme.color.lightGreen};
    }
  }
`
