import React from "react"
import { SearchPanelWrapper, CloseButton } from "./styles"
import crossIcon from "@iconify-icons/icomoon-free/cross"
import ISearchPanel from "./types"
import algoliasearch from 'algoliasearch';
import 'instantsearch.css/themes/satellite.css';
import {
    InstantSearch,
    Hits,
    SearchBox,
    Pagination,
    Highlight,
    Configure,
} from 'react-instantsearch-dom';


const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_ADMIN_KEY
);

const SearchPanel = ({ togglePanel, isPanelActive }: ISearchPanel) => {
  return (
    <SearchPanelWrapper isPanelActive={isPanelActive}>
        <div onClick={() => togglePanel()}>
        <CloseButton icon={crossIcon} />
        </div>
        <div className="ais-InstantSearch">
            <InstantSearch indexName="demo_ecommerce" searchClient={searchClient}>
                <SearchBox
                    translations={{
                        placeholder: 'Szukaj',
                    }}/>
                <div>
                    <Hits hitComponent={Hit} />
                    <Configure hitsPerPage={2} />
                </div>
                <Pagination />
            </InstantSearch>
        </div>
    </SearchPanelWrapper>
  )
}

function Hit(props) {
    return (
        <div>
            <img src={props.hit.image} align="left" alt={props.hit.name} />
            <div className="hit-name">
                <Highlight attribute="name" hit={props.hit} />
            </div>
            <div className="hit-description">
                <Highlight attribute="description" hit={props.hit} />
            </div>
            <div className="hit-price">${props.hit.price}</div>
        </div>
    );
}

export default SearchPanel
