import styled from "styled-components"
import IButtonElement from "./types"

export const Element = styled.div<IButtonElement>`
  position: absolute;
  width: 36px;
  height: 4px;
  background-color: white;
  border-radius: 5px;
  top: ${props => props.top}px;
  transition: 0.3s;
`
