import * as React from "react"
import {useAuth0} from "@auth0/auth0-react";
import { MobileLoginButtonWrapper, MobileLoginButtonStyle } from "./styles";

const MobileLoginButton = () => {
    const {loginWithRedirect, logout, isAuthenticated} = useAuth0();

    if (!isAuthenticated) {
        return (
            <MobileLoginButtonWrapper>
                <MobileLoginButtonStyle onClick={() => loginWithRedirect()}>Zaloguj</MobileLoginButtonStyle>
            </MobileLoginButtonWrapper>
        );
    } else {
        return (
            <MobileLoginButtonWrapper>
                <MobileLoginButtonStyle onClick={() => logout()}>Wyloguj</MobileLoginButtonStyle>
            </MobileLoginButtonWrapper>
        )
    }
};

export default MobileLoginButton;