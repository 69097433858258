import React, { useState } from "react"
import {
  NavLink,
  RelativeSection,
  HeaderContainer,
  DesktopNav,
  MobileElement,
  DesktopElement,
  LogoLinkDesktop,
  LogoLinkMobile,
  LogoSearchContainer,
  SearchButton,
  SearchButtonWrapper,
} from "./styles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../../UI/Button"
import SearchInput from "../../UI/SearchInput"
import MobileSearchInput from "../../UI/MobileSearchInput"
import MenuButton from "../../UI/MenuButton"
import search32Regular from "@iconify-icons/fluent/search-32-regular"
import SearchPanel from "../../Elements/SearchPanel"
import LoginButton from "../../UI/LoginButton";
import DropDownMenu from "../../UI/DropDownMenu";
import IHeader from "./types"

const Header = ({ isNavBackground, unverifiedUserEmail }: IHeader) => {
  const [searchPanelActive, setSearchPanelActive] = useState<boolean>(false)

  const togglePanel = () => {
    setSearchPanelActive(!searchPanelActive)
  }

  return (
    <RelativeSection isNavBackground={isNavBackground}>
      <SearchPanel
        togglePanel={togglePanel}
        isPanelActive={searchPanelActive}
      />
      <HeaderContainer isNavBackground={isNavBackground}>
        <LogoSearchContainer>
          <LogoLinkDesktop to="/">
            <StaticImage
              width={120}
              height={20}
              src="../../../data/images/click4fit_logo_black.png"
              alt="logo"
              layout="fixed"
              placeholder="tracedSVG"
            />
          </LogoLinkDesktop>
          <LogoLinkMobile to="/">
            <StaticImage
              width={86}
              height={16}
              src="../../../data/images/click4fit_logo_black.png"
              alt="logo"
              layout="fixed"
              placeholder="tracedSVG"
            />
          </LogoLinkMobile>
          <DesktopElement>
            <SearchInput margin="0 0 0 50px" />
          </DesktopElement>
        </LogoSearchContainer>
        <MobileElement>
          <MobileSearchInput />
        </MobileElement>
        <DesktopNav>
          <SearchButtonWrapper onClick={() => togglePanel()}>
            <SearchButton icon={search32Regular} />
          </SearchButtonWrapper>
          <NavLink to="/trainers#trenerzy">NASI PARTNERZY</NavLink>
          <NavLink to="/category">KATEGORIE</NavLink>
          <NavLink to="/blog#blog">BLOG</NavLink>
            <LoginButton unverifiedUserEmail={unverifiedUserEmail} margin="0 0 0 20px" />
            <DropDownMenu unverifiedUserEmail={unverifiedUserEmail} />
          <Button margin="0 0 0 20px" slug="">
            ZOSTAŃ TRENEREM
          </Button>
        </DesktopNav>
        <MobileElement>
          <MenuButton />
        </MobileElement>
      </HeaderContainer>
    </RelativeSection>
  )
}

export default Header
