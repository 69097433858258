import styled from "styled-components"
import { Section } from "../../Global"

export const ColoredSection = styled(Section)`
  background-color: ${props => props.theme.color.darkGreen};
`

export const ContactWrapper = styled.div`
  padding: 40px 15px 40px 15px;
  & > p {
    color: ${props => props.theme.color.white};
  }
`
