import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Section, Container, FlexCenterRow } from "../../Global"
import { Icon } from "@iconify/react"
import IHeader from "./types"

export const NavLink = styled(AnchorLink)`
  transition: 0.3s;
  margin: 15px;
  &:hover {
    color: ${props => props.theme.color.lightGreen};
  }
`
export const SearchButtonWrapper = styled.div``

export const SearchButton = styled(Icon)`
  width: 42px;
  height: 42px;
  color: white;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.color.lightGreen};
  }
`

export const LogoSearchContainer = styled(FlexCenterRow)`
  margin-left: 15px;
`

export const DesktopNav = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`

export const LogoLinkDesktop = styled(AnchorLink)`
  display: flex;
  @media (max-width: 1200px) {
    display: none;
  }
`

export const LogoLinkMobile = styled(AnchorLink)`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`

export const MobileElement = styled.div`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
  }
`
export const DesktopElement = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    display: none;
  }
`

export const RelativeSection = styled(Section)<IHeader>`
  background-color: ${props =>
    props.isNavBackground === true ? props.theme.color.darkGreen : "unset"};
  position: relative;
  z-index: 1000;
`

export const HeaderContainer = styled(Container)<IHeader>`
  top: 0;
  position: ${props =>
    props.isNavBackground === true ? "relative" : "absolute"};
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    height: 68px;
  }
`
