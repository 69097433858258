import styled from "styled-components"

export const MobileLoginButtonWrapper = styled.div`
  margin: 30px 0 30px 0;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileLoginButtonStyle = styled.button`
  background: transparent;
  border: none;
  font-size: 16px;
  color: #FFFFFF;
  &:hover {
    cursor: pointer;
  }
`