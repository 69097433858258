import styled from "styled-components"

export const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const LandingBackground = styled.div`
  height: 100vh;

  & > section {
    height: 100%;
  }
`

export const LandingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    & > div > h1 {
      font-size: 5rem;
    }
  }
  @media (max-width: 500px) {
    & > div > h1 {
      font-size: 3rem;
    }
  }
`
