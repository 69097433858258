import styled from "styled-components";

export const Main = styled.div`
  background: transparent;
  height: auto;
`;

export const DropDownContainer = styled.div`
  padding: 1px 6px;
  margin: 0 auto;
`;

export const DropDownHeader = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    margin: 0 0 0 0;
    font-size: 16px;
    padding: 1px 6px;
    color: #ffffff;
    &:hover {
        color: ${props => props.theme.color.lightGreen};
        cursor: pointer;
    }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 10.5em;
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #000000;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  &:hover {
    color: ${props => props.theme.color.lightGreen};
    cursor: pointer;
  }
`;