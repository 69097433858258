import styled, { css, keyframes } from "styled-components"
import IMobileMenu from "./types"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const LinkAnim = keyframes`
  0% {
    transform: translateY(50px);

  }
  1% {
    opacity: 0;
  }
  99%{
     transform: translateY(0px);
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
  }
`

export const LinkWrapper = styled.div`
  margin: 30px 0 30px 0;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobileNavLink = styled(AnchorLink)`
  z-index: 1100;
`

export const MobileMenuContainer = styled.div<IMobileMenu>`
  display: flex;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1100;
  width: 100vw;
  height: 100vh;
  transform-style: preserve-3d;
`

function createCSS() {
  let styles = ""
  for (let i = 0; i < 8; i += 1) {
    styles += `& > nav > div:nth-child(${i}) {
      opacity: 0;
      animation-delay: ${i / 15}s; 
      animation-fill-mode: forwards;
    }   
     `
  }
  return css`
    ${styles}
  `
}

export const MenuCard = styled.div<IMobileMenu>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  z-index: 1100;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: ${props =>
    props.isActive
      ? css`perspective(1500px) scale(1, 1) `
      : css`perspective(500px) scale(3, 3)
  `};
  background-color: ${props =>
    props.isActive ? props.theme.color.darkGreen : "transparent"};

  & > nav > div {
    animation: ${props =>
      props.isActive ? css`1s ease-out ${LinkAnim}` : "none"};
    animation-fill-mode: forwards;
  }
  ${createCSS()};
`
