import * as React from "react"
import {useAuth0} from "@auth0/auth0-react";
import {LoginButtonStyle} from "./styles";
import ILoginButton from "./types";

const LoginButton = ({ unverifiedUserEmail, margin }: ILoginButton) => {
    const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();

    if(isLoading) {
        return (
            <></>
        )
    }

    if (!isAuthenticated && !unverifiedUserEmail) {
        return (
            <LoginButtonStyle margin={margin} onClick={() => loginWithRedirect()}>
                ZALOGUJ
            </LoginButtonStyle>
        );
    } else {
        return <></>
    }
};

export default LoginButton;