import * as React from "react"
import { PropsWithChildren } from "react"
import IButton from "./types"
import {
  ButtonClickable,
  ButtonLink,
  ButtonWrapper,
  BtnGreenSpan,
  BtnBlackSpan,
} from "./styles"

const Button = ({
  children,
  margin,
  type,
  slug,
}: PropsWithChildren<IButton>) => {
  return (
    <ButtonWrapper type={type && type} margin={margin && margin}>
      <ButtonLink to={"/" + slug}>
        <ButtonClickable type={type && type}>
          {type === "landing" && <BtnBlackSpan>{children}</BtnBlackSpan>}
          {type === "blog" && <BtnBlackSpan>{children}</BtnBlackSpan>}
          {type === "advertise" && children}
          {!type && <BtnGreenSpan>{children}</BtnGreenSpan>}
        </ButtonClickable>
      </ButtonLink>
    </ButtonWrapper>
  )
}

export default Button
