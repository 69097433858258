import styled from "styled-components"
import ILoginButton from "./types";

export const LoginButtonStyle = styled.button<ILoginButton>`
  transition: 0.3s;
  margin: ${props => (props.margin ? props.margin : "unset")};
  background: transparent;
  font-weight: bold;
  border: none;
    outline:none;
    font-size: 16px;
    color: ${props => props.theme.color.white};
  &:hover {
    color: ${props => props.theme.color.lightGreen};
    cursor: pointer;
  }
`