import * as React from "react"
import { PropsWithChildren } from "react"
import ILayout from "./types"
import Footer from "../../Sections/Footer"
import GlobalStyles from "../../../globalStyles"
import Landing from "../../Sections/Landing"

const Layout = ({
  children,
  backgroundImage,
  isInBlog,
  hideLanding,
  unverifiedUserEmail
}: PropsWithChildren<ILayout>) => {
  return (
    <>
      <GlobalStyles />
      <Landing
        backgroundImage={backgroundImage}
        isInBlog={isInBlog && isInBlog}
        hideLanding={hideLanding}
        unverifiedUserEmail={unverifiedUserEmail}
      />

      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
