import styled from "styled-components"
import { Icon } from "@iconify/react"
import { ISearchPanelWrapper } from "./types"

export const SearchPanelWrapper = styled.div<ISearchPanelWrapper>`
  display: ${props => (props.isPanelActive === true ? "flex" : "none")};
  top: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 600;
  background-color: hsla(113, 12%, 17%, 95%);
  .ais-SearchBox-input {margin-bottom: 0;}
  .ais-SearchBox { max-width: 960px; overflow: hidden; margin: 10 ;margin-bottom: 10  }
  .ais-Pagination {margin-top: 1em;} 
  .ais-Pagination-list { list-style-type:none;margin-bottom: 50px}
  .ais-InstantSearch { width: 960px; overflow: hidden; margin-left: auto;margin-right: auto;margin-top:50px }
  .ais-Hits-item { margin-bottom: 1em; width: calc(50% - 1rem); list-style-type:none; margin-top:1em }
  .ais-Hits-item img { margin-right: 1em }
  .hit-name { margin-bottom: .5em }
  .hit-description { color: #888; font-size: 14px; margin-bottom: .5em }
  .hierarchicalMenu-container {margin-left: 0px }
`

export const CloseButton = styled(Icon)`
  color: white;
  width: 42px;
  height: 42px;
  position: fixed;
  right: 10px;
  top: 10px;
  &:hover {
    cursor: pointer;
  }
`
