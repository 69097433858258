import styled, { css } from "styled-components"

interface IMenuButtonWrapper {
  menuActive: boolean
}

export const MenuButtonWrapper = styled.div<IMenuButtonWrapper>`
  display: none;
  position: fixed;
  width: 36px;
  height: 32px;
  top: 16px;
  right: 16px;
  z-index: 1200;
  cursor: pointer;

  ${props =>
    props.menuActive &&
    css`
      & > div:nth-child(1) {
        transform: translateY(15px) rotateZ(45deg);
      }
      & > div:nth-child(2) {
        background-color: transparent;
      }
      & > div:nth-child(3) {
        transform: translateY(-15px) rotateZ(-45deg);
      }
    `}

  &:hover {
    & > div:nth-child(1) {
      transform: translateY(-3px);
    }
    & > div:nth-child(3) {
      transform: translateY(3px);
    }
    & > div {
      background-color: ${props => props.theme.color.lightGreen};
    }

    ${props =>
      props.menuActive &&
      css`
        & > div:nth-child(1) {
          transform: translateY(18px) rotateZ(45deg);
        }
        & > div:nth-child(2) {
          background-color: transparent;
        }
        & > div:nth-child(3) {
          transform: translateY(-18px) rotateZ(-45deg);
        }
      `}
  }

  @media (max-width: 1180px) {
    display: block;
  }
`
