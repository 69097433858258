import * as React from "react"
import { LandingWrapper, LandingBackground, LandingContent } from "./styles"
import { M } from "../../Global"
import Header from "../Header"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Button from "../../UI/Button"
import ILanding from "./types"
import { GatsbyImage } from "gatsby-plugin-image"

const Landing = ({ backgroundImage, isInBlog, hideLanding, unverifiedUserEmail }: ILanding) => {
  const bgImage = convertToBgImage(backgroundImage)
  return (
    <LandingWrapper>
      <Header isNavBackground={hideLanding} unverifiedUserEmail={unverifiedUserEmail} />
      {hideLanding === true ? (
        <></>
      ) : (
        <LandingBackground>
          <BackgroundImage
            Tag="section"
            // Spread bgImage into BackgroundImage:
            {...bgImage}
            preserveStackingContext
          >
            {!isInBlog && (
              <LandingContent>
                <M>
                  <h1>THE ULTIMATE FITNESS APP</h1>
                </M>
                <Button slug={""} type="landing" margin="10px">
                  Wypróbuj
                </Button>
              </LandingContent>
            )}
            <GatsbyImage image={backgroundImage} alt={"landing "} />
          </BackgroundImage>
        </LandingBackground>
      )}
    </LandingWrapper>
  )
}

export default Landing
